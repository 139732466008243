import React from "react";
import Layout from "../../components/general/Layout";
import EngineRoom from "../../components/examples/engine-room";
import { Helmet } from "react-helmet";
import {
    EXPERIENCES,
    markExperienceAsVisited
} from "../../components/general/store";

const Engine_Room = () => {
    markExperienceAsVisited(EXPERIENCES.ENGINE_ROOM.key);
    return (
        <Layout>
            <Helmet>
                <script src="https://unpkg.com/aframe-event-set-component@4.2.1/dist/aframe-event-set-component.min.js"></script>
            </Helmet>
            <EngineRoom />
        </Layout>
    );
};

export default Engine_Room;
